

/*import React from 'react';
import "./Footer.css";

function Footer() {
  return (
    <div>
       <div className="footer-container">
        <div className='footer-table'>
          <div className='footer-table-div'>
            <table className='footer-table1'>
              <tr><th>About Us</th></tr>
              <tr><p>Balagokulam is a forum for children to discover and manifest that divinity.
                 It will enable Hindu Children in the Bhagyanagar-Hyderabad to appreciate their cultural roots 
                 and learn Hindu Values. This is done through games, yoga, stories, bhajans, 
                 shlokas, arts and crafts.</p></tr>
                 <tr>  </tr>
                 <tr>  </tr>
                 <tr><a href='#'>balagokulam.bhagyanagar@gmail.com</a></tr>
                 
            </table>

            <table className='footer-table1'>
              <tr><th>Useful Links</th></tr>
              <tr><a href='#'>About</a></tr>
              <tr><a href='#'>Resources</a></tr>
              <tr><a href='#'>Events</a></tr>
              <tr><a href='#'>FAQs</a></tr>
              <tr><a href='#'>Contact Us</a></tr>
                 
            </table>


            <table className='footer-table1'>
              <tr><th>Balagokulam Units</th></tr>
              
                
                <tr><li>Manikanda Gokulam</li></tr>
                <tr><li>Yashoda Gokulam</li></tr>
                <tr><li>Ambadi Gokulam</li></tr>
                <tr><li>Vrinthavan Gokulam</li></tr>
        
                 
            </table>
          </div>


                </div>
                    <div id="footer"><p>@Copyright</p>
                    </div>
    </div>
    </div>
  )
}

export default Footer*/

import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter className=' text-white' style={{ backgroundColor: 'rgb(244, 166, 48)' }}>
      <MDBContainer className='p-4'>
        <MDBRow>
          <MDBCol lg="6" md="12" className='mb-4 mb-md-0'>
            <h3 className='text-uppercase'>Balagokulam</h3>

            <p style={{ textAlign:'justify' }}>
			Balagokulam is a forum for children to discover and manifest that divinity.
                 It will enable Hindu Children in the Bhagyanagar-Hyderabad to appreciate their cultural roots 
                 and learn Hindu Values. This is done through games, yoga, stories, bhajans, 
                 shlokas, arts and crafts.
            </p>
          </MDBCol>

          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            <h3 className='text-uppercase'>Useful Links</h3>

            <ul className='list-unstyled mb-0'>
              <li>
                <a href='./aboutUs' className='text-white'>
                  About Us
                </a>
              </li>
              <li>
                <a href='./resources' className='text-white'>
                  Resources
                </a>
              </li>
              <li>
                <a href='./spl_Events' className='text-white'>
                  Events
                </a>
              </li>
              <li>
                <a href='./contactUs' className='text-white'>
                  Contact Us
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className='mb-4 mb-md-0'>
            <h3 className='text-uppercase mb-0'>Balagokulam Units</h3>

            <ul className='list-unstyled'>
              <li>
              
                  Manikanda Gokulam, Contact No:9969198121
                
              </li>
              <li>
               
                  Yashoda Gokulam, Contact No:9959343506
                
              </li>
              <li>
               
                  Vrinthavan Gokulam, Contact No:9989894417
                
              </li>
              <li>
               
                  Gayathri Gokulam, Contact No:9440563997 
                
              </li>
			  <li>
               
                  Ambadi Gokulam ,Contact No:9397979401
                
              </li>
			  <li>
               
                  Geetha Gokulam, Contact No:7396341205
                
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgb(244, 166, 48)' }}>
        © 2020 Copyright
       
      </div>
    </MDBFooter>
  );
}