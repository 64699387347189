import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Navbar from '../Header/Navbar.js';
import Home from '../Pages/Home.js';
import About from '../Pages/About.js';
import Gallery from '../Pages/Gallery.js';
import Contact from '../Pages/Contact.js';
import JoinUs from '../Pages/JoinUs.js';
import Focus from '../Pages/Focus.js';
import Resources from '../Pages/Resources.js';
import  Children from '../Pages/Children.js';
import Parents from '../Pages/Parents.js';
import Footer from '../Footer/Footer.js';





import Committee from "../Pages/Committee.js";


import Spl_Events from "../Pages/Spl_Events.js";
import Weekly from "../Pages/Weekly.js";
import SKJ from "../Pages/SKJ.js";


import AmruthaVachanm from '../Pages/AmruthaVachanam.js';

import Units from "../Pages/unit.js";

import Recent from "../Pages/Recent.js";


export default function Navigate() {
  return (
    <Router>
        <div>
            <div><Navbar/></div>
            <Routes>/aboutUs
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/aboutUs" element={<About />}></Route>
            <Route exact path="/gallery" element={<Gallery />}></Route>
            <Route exact path="/contactUs" element={<Contact />}></Route>
            <Route exact path="/joinUs" element={<JoinUs />}></Route>
            <Route exact path="/focusOnMonth" element={<Focus />}></Route>
            <Route exact path="/resources" element={<Resources />}></Route>
            <Route exact path="/childrenCorner" element={<Children />}></Route>
            <Route exact path="/parentsCorner" element={<Parents />}></Route>

            
        

            <Route exact path="/adminCommittee" element={<Committee />}></Route>


            <Route exact path="/spl_Events" element={<Spl_Events />}></Route>
            <Route exact path="/weekly_Programs" element={<Weekly />}></Route>
            <Route exact path="/skj_Programs" element={<SKJ />}></Route>

            <Route exact path="/amruthaVachanam" element={<AmruthaVachanm />}></Route>

            <Route exact path="/unit" element={<Units />}></Route>

            <Route exact path="/recent_events" element={<Recent />}></Route>




            </Routes>
         <div><Footer/></div>
        </div>
    </Router>
   
  );
}
