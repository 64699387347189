import React from 'react';
import './Contact.css';
import AOS from 'aos';

import { Link } from 'react-router-dom';

import icon from '../../images/Logo.png';
import { useRef } from 'react';
import { useState } from 'react';




import emailjs from '@emailjs/browser';



import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';




function MyVerticallyCenteredModal(props) {
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
  centered
  
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
       <h1 className='modal_heading'> Thank you for reaching out us</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className='modal_div'>

        {/*<img src={img_modal} className='modal_bg_img'></img>*/}
<p>We will contact you in a while , please cooperate with us, </p>
        
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}


function Contact() {


  AOS.init();

  



  const form = useRef();

  const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);


  


  const sendEmail = (e) => {
    e.preventDefault();
  
      
    emailjs
      .sendForm('service_616faua', 'template_t9ir572', e.target, 
         'ajGBXGIZvtcMMp25H',
      )


      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset();
      setModalShow(true);
      
  };





  return (
    <div className='Contact'>
      <div>
    
       <div className='Contact_content'  data-aos="fade-right" data-aos-duration="1000" >
        

     
          <img src={icon} className='bgIcon_contact'></img>
    <div className='Contact-para'>
          <p>Balagokulam Bhagyanagar , Dr. A S Rao Nagar, ECIL, Hyderabad-62 </p>
          <p>Mob: 94412 78721, 91779 36888, 99082 09475</p>
          <p>Email: <br/>    <Link className='contactUs_link' to='https://www.bhagyanagarbalagokulam@gmail.com'> bhagyanagarbalagokulam@gmail.com</Link></p>
          <p>Facebook: <Link className='contactUs_link' to='https://www.facebook.com/balagokulam.bhagyanagr'> www.facebook.com/balagokulam.bhagyanagr</Link></p>
          <p>YouTube: <Link className='contactUs_link' to='https://www.youtube.com/@balagokulambhagyanagar4278'>https://www.youtube.com/@balagokulambhagyanagar4278</Link></p>
    </div>
       </div>

      </div>

      <div className='contact_form' data-aos="fade-left" data-aos-duration="1000" >
        <h3>Send us a Message</h3>


        <form  ref={form} onSubmit={sendEmail}>


        
          <div className="form-group">
            <input type='text' name='name' placeholder='Your Name ' autoComplete="off" required></input>
          </div>

          <div className='form-group'>
            <input type='email' name='email' placeholder='Your Email Id ' autoComplete="off" required></input>
          </div>

          <div className='form-group'>
            <textarea  name='message' placeholder='Your message ' required ></textarea>
          </div>
          <button type='submit' >Send Message</button>

          <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />



         
        </form>
      </div>
    </div>
  )
}

export default Contact