import React from 'react';
import './JoinUs.css'
import { useState } from 'react';

import { InputAdornment } from '@mui/material';

import  axios  from 'axios';


function JoinUs() {


  
 /* const [Name, setName] = useState('');
  const [Place, setPlace] = useState('');
  const [Address, setAddress] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [WhatsApp, setWhatsApp] = useState('');

  const [joinStatus,setjoinStatus]=useState("");
  const [isError, setIsError] = useState(false);


const [joiners,setJoiners]=useState({
    name:'',
    email:'',
    address:'',
    phone:'',
    whatsapp:''


  })



 const handleInput=(e)=>{
  setJoiners({...joiners,[e.target.value]: e.target.value})
 }


 const handleSubmit = (event) => {
  event.preventDefault();
  console.log(joiners);
 }


 const handleSubmit = (event) => {

    event.preventDefault();

    event.persist();

   const data={Name,Place,Address,Email,Phone,WhatsApp};


   console.log(data);
    
   alert("Submitted successfully");

   event.target.reset()

  
    
  
  };

 
  /*const joining=(e)=>{
    e.preventDefault();
    axios.post("http://localhost:3001/join",{
      name:Name,
      place:Place,
      address:Address,
      phone:Phone,
      whatsapp_no:WhatsApp,
      email_id:Email
    } ) .then((response)=>{
      if(response.data.message){
        setjoinStatus(response.data.message);
        
      }else{
        setjoinStatus("JOINED SUCCESSFULLY");
        console.log("success")
      }
      console.log(joinStatus);
    })
  }
*/


  return (
   

    <div className='join'>
      <div className='join_headings'>

      <h1>Namasthe</h1>

          <h3>Please fill the below form to join us </h3>

      </div>

 

    <div className='JoinUs-Form'>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdu8huJajESGJfew3gI_vtIJrqK8xNVh6r0vruZaJUm0jEfKA/viewform?embedded=true" 
    width="640" height="1081" frameborder="0" marginheight="0" marginwidth="0" >Loading…</iframe>
  </div>


{/*<div className='join_form_body'>
 
<form onSubmit={handleSubmit} className='join_form2'>


        <div className="form-group">

            <input type='text' name='name' placeholder='Your Name ' autoComplete="off"   
            required onChange={(event) => setName(event.target.value) }></input>

        </div>

          <div className='form-group'>

            <input type='text' name='place' placeholder='Your place ' autoComplete='off' 
            required  onChange={(event) =>setPlace(event.target.value) } ></input>

          </div>

          <div className='form-group'>

            <textarea  name='address' placeholder='Your address ' autoComplete='off' 
            required onChange={(event) =>setAddress(event.target.value)} ></textarea>

          </div>

          <div className='form-group'>

            <input type='email' name='email' placeholder='Your Email Id 'autoComplete='off' 
            required  onChange={(event) =>setEmail(event.target.value)} ></input>

          </div>

          <div className='form-group'>

            <input type='text' name='phone' placeholder='Your mobile number '  error={isError} autoComplete='off'
            required onChange={
              (event) =>{

              setPhone(event.target.value);
              if (event.target.value.length > 10) {
                 setIsError(true);
            }
            setIsError(false);
        }}
              


       
              
              
              
              ></input>

          </div>

          <div className='form-group'>

            <input type='text'  name='whatsapp' placeholder='Your WhatsApp number ' autoComplete='off' 
            required  onChange={(event) =>setWhatsApp(event.target.value)} ></input>

          </div>

          <button type='submit' >submit</button>
</form>




</div>*/}
  
</div>


   
  )

}
export default JoinUs