/*import React from 'react';*/
import React, { Component, useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import ReactCardSlider from 'react-card-slider-component';


import { CCarousel,CCarouselItem,CImage } from '@coreui/react';

import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";


import "./Home.css"; 
import img1 from "../../images/KrishnaImg.jpg"
import img2 from '../../images/Img-Car5.JPG';
import img3 from '../../images/spl-card-img.jpg';
import img4 from '../../images/classs/7.bmp';
import img5 from '../../images/SKJ/1661076_732965933515545_2283107751212284966_n.jpg';
import img6 from '../../images/SKJ/1661076_732965933515545_2283107751212284966_n.jpg';
import img7 from '../../images/SKJ/skj_dance.jpeg';
import img8 from '../../images/SKJ/skj_pataka.jpeg';


import pdf_modal from '../../Pdf_files/Thiruvathira_Notice.pdf';



import resource2 from '../../images/resource2.jpg';
import calender2 from '../../images/cal400.png';

import children1 from '../../images/children3.png';

import parents1 from '../../images/parents1.png';

import bg_image from '../../images/para_bg_200.jpg';

import img_modal from '../../images/Thiruvathira/thiruvathira_pic.jpg'



//import 'bootstrap/dist/css/bootstrap.css';  
import Carousel from 'react-bootstrap/Carousel'; 
import { Card } from 'react-bootstrap';


import Marquee from "react-fast-marquee";

import AOS from 'aos';

import 'aos/dist/aos.css';
import { blue, orange } from '@mui/material/colors';

import { carousel } from 'react-bootstrap';


import Modal from 'react-bootstrap/Modal';

import { Button } from 'react-bootstrap';




function MyVerticallyCenteredModal1(props) {
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
  centered
  
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
       <h1 className='modal_heading'>Hindu Samrajya Dinam</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className='modal_div'>

        {/*<img src={img_modal} className='modal_bg_img'></img>*/}

       {/*} <h4>Visu Kani darshanam</h4>
        <h4>Time: 8.00 AM</h4>
              <h4>Venue :Sabari Sadan , Kamala Nagar ,ECIL</h4>
              <br/>
              <h5>Cultural programmes : </h5>
              <ul  >
                <li >Vishu Kani Darshan </li>
                
                <li>Cultural events presented by children & women from various Gokulams</li>
              
  </ul> */}

 

  <p style={{textAlign:'justify'}}>June 4 is celebrated every year as Hindu Samrajya Diwas to mark the coronation anniversary of
     Chhatrapati Shivaji Maharaj. It is believed that the Hindu kingdom came into being with Shivaji's
      coronation</p>

      <p style={{color:'blue'}}>
       <b> Venue to be announced later</b>
      </p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}




function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
  centered
  
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
       <h1 className='modal_heading'>World Environment Day</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className='modal_div'>

        {/*<img src={img_modal} className='modal_bg_img'></img>*/}

       {/*} <h4>Visu Kani darshanam</h4>
        <h4>Time: 8.00 AM</h4>
              <h4>Venue :Sabari Sadan , Kamala Nagar ,ECIL</h4>
              <br/>
              <h5>Cultural programmes : </h5>
              <ul  >
                <li >Vishu Kani Darshan </li>
                
                <li>Cultural events presented by children & women from various Gokulams</li>
              
  </ul> */}

 

  <p style={{textAlign:'justify'}}>The UN General Assembly designated 5 June as World Environment Day, 
  marking the first day of the Stockholm Conference on the Human Environment. Another resolution,
   adopted by the General Assembly the same day, led to the creation of UNEP.</p>

      <p style={{color:'blue'}}>
       <b> Venue to be announced later</b>
      </p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
  centered
  
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
       <h1 className='modal_heading'>International Yoga Day</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className='modal_div'>

        {/*<img src={img_modal} className='modal_bg_img'></img>*/}

       {/*} <h4>Visu Kani darshanam</h4>
        <h4>Time: 8.00 AM</h4>
              <h4>Venue :Sabari Sadan , Kamala Nagar ,ECIL</h4>
              <br/>
              <h5>Cultural programmes : </h5>
              <ul  >
                <li >Vishu Kani Darshan </li>
                
                <li>Cultural events presented by children & women from various Gokulams</li>
              
  </ul> */}

 

  <p style={{textAlign:'justify'}}>The International Day of Yoga has been celebrated across the world 
  annually on June 21 since 2015, following its inception in the United Nations General Assembly in 2014.
   Yoga is a physical, mental and spiritual practice which originated in ancient India.
    The Indian Prime Minister Narendra Modi, in his UN address in 2014, had suggested the date of June 21, 
    as it is the longest day of the year in the Northern Hemisphere and shares a special significance 
    in many parts of the world.</p>

      <p style={{color:'blue'}}>
       <b> Venue to be announced later</b>
      </p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}








function Home(props) {

  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);

  



 
  AOS.init();


  


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [scrollableModal, setScrollableModal] = useState(false);
  

  return (


  

   
    /*-----Home page part 1  Heading , Krishna Photos  , Join Us Paragragh ------*/

  <div className='home_div'>
        <div className='Home-Container'>



              <div className='Main-Heading'>
                  <h1 className='HomeHeading'>Balagokulam Bhagyanagar</h1>
                  <div className='Home_quote' >
                  <q>धर्मस्य दुर्लभो ज्ञाता सम्यक् वक्ता ततोऽपि च।
                  श्रोता ततोऽपि श्रद्धावान् कर्ता कोऽपि ततः सुधीः।।</q>
                  </div>
              </div>



  {/*            <div className='blink'>
   <Marquee className='text'><p><b style={{color:orange}}>Gokulam classes will remain closed for the month of May.</b></p>
   <p> &nbsp;The classes will be commenced from June</p>&nbsp;&nbsp;&nbsp;&nbsp;<p> 
    </p>&nbsp; &nbsp;&nbsp;&nbsp;<p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></Marquee>
  </div>*/}
  
        
       
              <div className='Home_container2'>

            <div className='Krishna_image1' data-aos="fade-up" data-aos-duration="1000"> 
              
                    <img src={img1} className='Krishna_Image_img'></img>
              </div> 

              <div className='Join_Us_part'>

                
                 
                    
                    <div className='Para_1' data-aos="fade-up" data-aos-duration="1000">
                      <div className='Para_bg' data-aos="fade-up" data-aos-duration="1000">
                          <p>Balagokulam, as an organization, was formed in the early 1970s with a distinct goal of countering the diminishing cultural values caused by the deficiencies in the modern education system. Like the eternal cosmic music coming out of the flute of Lord Krishna, Balagokulam envisions to spread the spiritual essence of Dharma among the young and budding minds

Honoured as one among the founding members of Global Network of Religions for Children (GNRC), a recognized body of UNICEF, Balagokulam has stamped its ubiquous presence with over 3000 branches around the globe.</p>
                    </div>
                   {/*} <img src={bg_image} >
                    </img>*/}
               
       

     </div>   
     </div>
                   
                    
                   <a href='/joinUs' > 
                       <button className='JoinUs_Button'>Join Us</button>
                   </a>         
       </div>
  </div>
   

   








   {/* Third part Home Page-----*/}


      <div className='Activities-div'  >
        <div className='Activities-card' >
         <div className='Activities-card-intro' data-aos="fade-up" >
        <h1 >Our Activities</h1>
        <p>Balagokulam Bhagyanagar is continuously and immensely engaged in various activities based on
Indian spiritual values and ethics for the overall personality development of children.
Accordingly, an academic programme has been developed and implemented through
scheduled weekly classes, engagement of expert lectures, conducting yearly programmes,
observing Indian festivals and by organising workshops, debates, group activities, sports events
and educational excursions and numerous educational programmes. Deviating from the
regular route of formal education, Balagoklam has ventured in to a world of informal education
imparting knowledge in every field which will mould the children in to responsible citizens.</p>
<p>A brief overview of these activities is given below:
</p>
</div>



         <div className='cards' >
     
          <div className='Activities-card1' data-aos="fade-right" data-aos-duration="1000">
              <img src={img4} alt='' className='card-img'></img>
              <div class="card-body">
                  <h4 class="card-title">Weekly Classes</h4>
                  <p class="card-text">The Balagokulam class is held once in a week which lasts for 90 minutes.
                    The schedule has activities focused on a curriculum which has been designed to ensure 
                    overall personality development of children based on our traditional values and Indian
                     heritage. </p>
                  <a href="./weekly_Programs" class="btn btn-primary">View details</a>
                  </div>
        </div>

        

        <div className='Activities-card1' data-aos="fade-up" data-aos-duration="2000">
              <img src={img3} alt='' className='card-img'></img>
              <div class="card-body">
                  <h4 class="card-title">Balagokulam Special Events</h4>
                  <p class="card-text">Balagokulam conducts special socio cultural events on various occasions and festivals that have a cultural significance. 
                  These events bring all the Balagokulam units together and gives us an opportunity to interact as one big family.</p>
                  <a href="./spl_Events" class="btn btn-primary">View details</a>
                  </div>
        </div>

        <div className='Activities-card1' data-aos="fade-left" data-aos-duration="1000">
              <img src={img5} alt='' className='card-img'></img>
              <div class="card-body">
                  <h4 class="card-title">Sreekrishna Jayathi Celebrations</h4>
                  <p class="card-text">Sree Krishna Janmashtami which marks the birth of Bhagawan Sree Krishna 
                  is celebrated by Balagokulam across the twin cities in a grand way. Janmashtami is celebrated
                   with lots of enthusiasm across various Balagokulams in Hyderabad. </p>
                  <a href="./skj_Programs" class="btn btn-primary">View details</a>
                  </div>
        </div>

      
      </div>
     
     
    </div>
</div>

  {/* Third part Home Page ----- Photo Carousel */}

  <div className='Event_div'>    

 <div className='ImageSlider'>
           
       {/* <div id="myCarousel" className="carousel slide" data-ride="carousel">*/}

        <h2>A note from our President:</h2>


          <div className='Youtube_video'>
              
          <iframe width="650" height="450" 
src="https://www.youtube.com/embed/SeYtAoDwdog?si=ey4vnP_cyyGIVV2Y&rel=0 "> {/* https://youtu.be/o5-jmqUtFpE?si=pJCDtZkAhRhDG7YA */}
</iframe>

          </div>    
         
 
</div>


   <div className='Events_Cards'>

    <h1 className='event_h'>Events</h1>
        <div className='Upcoming'>

          <table className='upcoming_table' >
            <th colSpan={3} className='upcoming-heading'>
            <h3>Upcoming Events</h3>
            </th>
            <tr>
              <td className='Date_Column'><h1>02</h1> June 2024</td>
              <td className='Event_Column'><b>Hindu Samrajya dinam/Telangana formation day</b> </td>
              
              
             <br/>
             <div className='modal_btns'>
              <button  onClick={() => setModalShow1(true)} className='dt_bt_modal'>
        View Details
      </button>

      <MyVerticallyCenteredModal1
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      />


      <a href={pdf_modal} data-aos="fade-up" data-aos-duration="1000"> 
     {/* <button className='dn_bt_modal'>
        Download Brochure
      </button> */}
     </a>   

     </div>
      


             
            {/*}   <h4>Time: 6.30 PM</h4>
              <h4>Venue : Brindavan Gardens  Anandabagh X Road  Malkajgiri</h4>
              <br/>
              <h5>Cultural programmes : </h5>
              <ul  >
                <li >Thiruvathira</li>
                <li>Bathukamma</li>
                <li>Cultural events presented by children & women from various Gokulams</li>
              
</ul>*/}

            
            </tr>
            <tr>
              <td className='Date_Column'> <h1>05</h1> June 2024</td>
              <td className='Event_Column'><b>World Environment Day</b> </td>
              <br/>
             <div className='modal_btns'>
              <button  onClick={() => setModalShow2(true)} className='dt_bt_modal'>
        View Details
      </button>

      <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />


      <a href={pdf_modal} data-aos="fade-up" data-aos-duration="1000"> 
     {/* <button className='dn_bt_modal'>
        Download Brochure
      </button> */}
     </a>   

     </div>
            </tr>

            <tr>
              <td className='Date_Column'> <h1>21</h1> June 2024</td>
              <td className='Event_Column'><b>International Yoga Day</b> </td>
              <br/>
             <div className='modal_btns'>
              <button  onClick={() => setModalShow3(true)} className='dt_bt_modal'>
        View Details
      </button>

      <MyVerticallyCenteredModal3
        show={modalShow3}
        onHide={() => setModalShow3(false)}
      />


      <a href={pdf_modal} data-aos="fade-up" data-aos-duration="1000"> 
     {/* <button className='dn_bt_modal'>
        Download Brochure
      </button> */}
     </a>   

     </div>
            </tr>
            
          </table>



          

          
          
            
        </div>







        <div className='Recent'>

<table className='recent_table' >
  <th colSpan={2} className='recent-heading'>
  <h3>Recent Events</h3>
  </th>
  <tr>
    
    <td  className='Recent_Event_Column'> 
    <h3><b>Vishu Kani Darshan and Kaiyezhuth Masika(Hand-written Magazine) Prakashan</b> </h3>

     
              <br/>
              <p>

              </p>
    <span>   <a href='/recent_events' className='recent_link'>
      <button className='recent_button'>View details</button>
    </a></span>
 

    </td>
  </tr>
  
  
</table>







  
</div>










        {/*<div className='Recent'>

          <table className='recent_table' >
            <th colSpan={2} className='recent-heading'>
            <h1>Recent Events</h1>
            </th>
             
                <tr className='recent_row'><h2>Sreekrishna Jayanthi</h2></tr>
             
       <img src={img5}></img>
                
               <Carousel className='recent_car' autoPlay interval="2500" transitionTime="1500" infiniteLoop 
   showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} > 

 
                            


                <div className='recent_car_img'>  
                    <img src={img6} className='car_img1' />   

                </div>  
                <div className='recent_car_img'>  
                    <img src={img7} className='car_img1'/>   

                </div>
                <div className='recent_car_img'>  
                    <img src={img8} className='car_img1'/>   

                </div>    
               

              
                

              
             
          
</Carousel>




               
             
            
          </table>
        </div>*/}



        </div>
</div>

  <div>
    <div className='Table-cards'>
      <table>
        <tr>
        <td className='focus_month'>
          <h2>Balagokulam Calender</h2>
          <a href='/focusOnMonth'><img src={calender2} className='focus-month-img'></img></a>
        </td>

        <br/>

        <td className='resource'>
          <h2>Resources</h2>
          <a href='/resources'><img src={resource2} className='resource-img'></img></a>
        </td>
        </tr>
        
      </table>
    </div>
   </div>


  </div>
      
     
         

        



            

  )
              
}



export default Home