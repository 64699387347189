import React from 'react';
import './Focus.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import img1 from '../../images/SPL/thiruvathira.jpg';
import img2 from '../../images/Thiruvathira/thiruvathira_pic.jpg';

import img3 from '../../images/Calender/Gokulam Calander-2023-2024_page-0001.jpg';
import img4 from '../../images/Calender/Gokulam Calander-2023-2024_page-0002.jpg';
import img5 from '../../images/Calender/Gokulam Calander-2023-2024_page-0003.jpg';
 

import pdf1 from '../../Pdf_files/Thiruvathira_Notice.pdf';


import AOS from 'aos';

import 'aos/dist/aos.css';




function Focus() {
  return (
    <div className='focus'>

    <div className='focus-content'>
        <div>
        <div className='Calender_div'>

         
<div className='cal_img'>
<img src={img3}></img>
<img src={img4}></img>
<img src={img5}></img>
</div>

<div>
    
        </div>
        </div>
        </div>
        
             
  <div className='focus_body'>

    

 {/*<div  className='Thiruvathira_text'>
    <h1>26</h1><h2> December 2023 , Tuesday</h2><h1>Thiruvathira-Mathrudinam Celebrations</h1> 
   

    

    <div className='focus_img1'>
      <img src={img1}></img>
    </div>

 
  </div> 

<div className='focus_img2'>
      <img src={img2}></img>
      <a href={pdf1} className='btn btn-primary'>Download Brochure </a>
  </div>*/}



    </div>

        
                  </div>
                  
  



  {/*<div>
    <div>
      <h2>
      Thiruvathira-Mathrudinam Celebrations
      </h2>
      <h3>
      26 Dec 2023, Tuesday
      6:00 pm to 10:00 pm
      </h3>
      <b><h4>Brindavan Gardens, Anandbagh X Road.</h4></b>
      <h5>You are invited to attend the event with family and friends</h5>
    </div>
</div>*/}
  

    </div>
  )
}

export default Focus