import React from 'react';
import './Committee.css';
import img1 from '../../images/Committee/New Office Bearers 2023-25_page-0001.jpg';

import img2 from '../../images/Committee/New Office Bearers 2023-25_page-0002.jpg';

function Committee() {
  return (
    <div>
      <div className='Committee_div'>

         
          <div className='com_img'>
          <img src={img1}></img>
          <img src={img2}></img>
          </div>

          <div>
              
          </div>


      </div>
    </div>
  )
}

export default Committee