import React from 'react'
import img1 from "../../images/Img-Car1.JPG"
import img2 from "../../images/Img-Car2.JPG"
import './About.css';



import AOS from 'aos';

import 'aos/dist/aos.css';




function About() {

  AOS.init();

  return (
    <div>
        
    <div className='About-us-div'>
       
        <div className='About_para_bg'>


           <span>  <div className='About_para'  data-aos="fade-left" data-aos-duration="1000">

                <h1>About Us</h1>
                    <p><b>Namasthe </b>,  Balagokulam, as an organization, was formed in the early 1970s with a distinct
                       goal of countering the diminishing cultural values caused by the deficiencies 
                       in the modern education system. Like the eternal cosmic music coming out of the
                       flute of Lord Krishna, Balagokulam envisions to spread the spiritual essence 
                       of Dharma among the young and budding minds</p>
                      <p>Honoured as one among the founding members of Global Network of Religions for Children (GNRC),
                        a recognized body of UNICEF, Balagokulam has stamped its ubiquous presence with over 3000 
                        branches around the globe.</p>
                        <h3>Balagokulam Bhagyanagar</h3>
                        <p>Balagokulam Bhagyanagar, affiliated to Balagokulam Kerala, was established in Hyderabad on the
                            Vijaya Dashami Day in 2009. Since then, it has been acting as the Baton bearer in igniting the
                            minds of children with Indian cultural values and it has paved the way for their transformation
                            into responsible Indian citizens through its designated activities and socio-cultural influence</p>
                            <p>Balagokulam Bhagyanagar, affiliated to Balagokulam Kerala, was established in Hyderabad on the
                            Vijaya Dashami Day in 2009. Since then, it has been acting as the Baton bearer in igniting the
                            minds of children with Indian cultural values and it has paved the way for their transformation
                            into responsible Indian citizens through its designated activities and socio-cultural influence</p>
              
              
              </div>


              </span>
              <div className='About-link-div'>
          
            <a href='./adminCommittee' className='About-admin-link'> <h2>Administrative Commitee Members</h2></a>

            </div>

            <div className='About-link-div'>
          
          <a href='./unit' className='About-admin-link'> <h2>Our Units</h2></a>

          </div>



        
    </div>
    <div className='About_sideImg'>


    <div data-aos="fade-left" data-aos-duration="1000" >
      <img src={img1} className='About_img'></img>
  </div>

  <div data-aos="fade-left" data-aos-duration="1000" >
      <img src={img2} className='About_img'></img>
  </div>
  
  </div>

    </div>

    </div>
  )
}

export default About