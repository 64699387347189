import React from 'react'
import "./Weekly.css"; 
import img1 from '../../images/Img-Car2.JPG';
import img2 from '../../images/classs/IMG-20160728-WA0124.jpg';
import img3 from '../../images/classs/IMG_9459.JPG';
import img4 from '../../images/classs/IMG_9480.JPG';
import img5 from '../../images/classs/DSC_0481.JPG';
import img6 from '../../images/classs/IMG_9436.JPG';

import bg_image from '../../images/orange4.jpg';

import AOS from 'aos';

import 'aos/dist/aos.css';





function Weekly() {



  AOS.init();


  return (
    <div className='weekly'>
       <h1  className='weekly_heading'>
        Weekly Classes
      </h1>
    <div className='weekly_classes'>
   
      <div className='weekly_part1'  data-aos="fade-up" data-aos-duration="1000">



     
     
      <div className='weekly_contents'>

         <div className='weekly_intro'>
         <p>
         The Balagokulam class is held once in a week which lasts for 90 minutes.  
         The schedule has activities focused on a curriculum which has been designed
          to ensure overall personality development of children based on our traditional 
          values and Indian heritage.
         </p>

         <h3>
          The various sessions include :
         </h3>
         </div>


         <div  className='weekly_list'>
         <ol >
          <li style={{listStyleType:'decimal'}}>
              <h4> Prarthana Kalamsham/Session for Prayer (10 minutes) : </h4>
              <p>
              The class starts with the lighting of a lamp and garlanding of Bhagawan Sree Krishna 
              followed by prayer, attendance, and report of previous Gokulam class.
              </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
          <h4> Session for individual performance (20 minutes) : </h4>
              <p>
              Children are provided with the opportunity to exhibit their individual talents such as elocution,
               artistic skills, fine arts, crafts, and leadership qualities.

              </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
          <h4>  Padana Kalamsham/Session for the enrichment of knowledge (20 minutes) : </h4>
              <p>
              The Gokulam Rakshadhikari handles various lessons covering an array of topics like 
              excerpts from history, insight into the Indian philosophy and epics, knowledge of Sanskrit,
               learning of Bhajans, encouragement and practice of speech and writing in Mother Tongue etc.


              </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
          <h4>Vishrama Kalamsham/Session for Entertainment (10 minutes)</h4>
              <p>
              Various games in the form of a group task, individual task, and series task are organized 
              by which children learn to mingle and work as a group

              </p>
          </li>
          <li style={{listStyleType:'decimal'}}>
          <h4>  Parisheelana Kalamsham/Session for Practice (20 minutes) : </h4>
              <p>
              During this session, the Rakshadhikari handles the practice of the following subjects:
              <ul>
              <li style={{listStyleType:'circle'}}> Chanting of Shlokas from Bhagavad Gita, Upanishads, and Subhashithas.</li> 

                <li  style={{listStyleType:'circle'}}>Patriotic and devotional songs</li>

                <li  style={{listStyleType:'circle'}}>Presenting Poetry</li>

                <li  style={{listStyleType:'circle'}}> Practicing of Yoga and Pranayama</li>
                </ul>
              </p>
          </li>
          <li style={{listStyleType:'decimal'}}>
          <h4> Sandesha Kalamsham (10 minutes) :</h4>
              <p>
              This session is used by the patron to guide the children towards developing into perfect
               individual by narrating stories having morals and ethical values. The session is concluded
                by chanting Mangalam followed by the distribution of the Prasadam.


              </p>
          </li>

         </ol>
         </div>
      </div>
    </div>
    <div className='weekly_part2'>
      <div className='weekly_img' data-aos="fade-left" data-aos-duration="1000">

      <img src={img4}></img>

     </div> 
     <div className='weekly_img' data-aos="fade-left" data-aos-duration="1000">

<img src={img6}></img>

</div> 
<div className='weekly_img' data-aos="fade-left" data-aos-duration="1000" > 

<img src={img5}></img>

</div> 
    </div>
    </div>

   

    </div>

  )
}

export default Weekly
