//import Header from './Components/Header/header.js';
import React  from 'react';
import './App.css';
import Navbar from './Components/Header/Navbar.js';
import Home from './Components/Pages/Home.js';
import Footer from './Components/Footer/Footer.js'
import Navigate from './Components/Navigation/Navigate.js'
import { BrowserRouter as Router,Route } from 'react-router-dom';

//import img1 from "https://www.google.com/imgres?imgurl=https%3A%2F%2Fmir-s3-cdn-cf.behance.net%2Fprojects%2F404%2F3ec893108861381.Y3JvcCwzMzA4LDI1ODcsMCwzNjA.jpg&tbnid=HZf-zuC5GUMboM&vet=12ahUKEwiMqbm9_rD-AhVyCrcAHex1ASoQMygBegQIARA7..i&imgrefurl=https%3A%2F%2Fwww.behance.net%2Fprasanthcp&docid=k5PmaGnKNRooKM&w=404&h=316&itg=1&q=balagokulam%20logo%20svg%20download&ved=2ahUKEwiMqbm9_rD-AhVyCrcAHex1ASoQMygBegQIARA7"






function App() {







  return (
  <div className='App'>
 
      

      <Navigate/>
       

   
    
               
    </div>
  );
}

export default App;
