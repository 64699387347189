import React from 'react';

import './Resources.css';
import { Button } from 'react-bootstrap';

import pdf1 from '../../Pdf_files/Amrutha Vachanam.pdf';
import pdf2 from '../../Pdf_files/Bhajans.pdf';
import pdf3 from '../../Pdf_files/Nithya Shlokas.pdf';
import pdf4 from '../../Pdf_files/Balagokualm Prayer2.pdf';
import pdf5 from '../../Pdf_files/BG_Syllabus.pdf';
import pdf6 from '../../Pdf_files/Ramayana Shlokas.pdf';

import pdf7 from '../../Pdf_files/Subhashitham.pdf';
import pdf8 from '../../Pdf_files/Bhagavad Gita Malayalam Text with Translation.pdf';
import pdf9 from '../../Pdf_files/Bhagavad Githa.pdf';
import pdf10 from '../../Pdf_files/Bharath Ki Naari Shakthi.pdf';
import pdf11 from '../../Pdf_files/Daily slokas.pdf';
import pdf12 from '../../Pdf_files/BALAGOKULAM DAIRY KTM-1.pdf';

import pdf13 from '../../Pdf_files/Games_catalogue.pdf';
import pdf14 from '../../Pdf_files/Geetha Dhyanam.pdf';
import pdf15 from '../../Pdf_files/Hindu_Rituals.pdf';
import pdf16 from '../../Pdf_files/Kuttikavithakal.pdf';
import pdf17 from '../../Pdf_files/Sanadana Darma Padanam 1-10.pdf';
import pdf18 from '../../Pdf_files/teacherhandbook_2.pdf';

import pdf19 from '../../Pdf_files/stories.pdf';
import pdf20 from '../../Pdf_files/Yoga day Celebrations.pdf';


import Prayer from '../../Audio_files/Karuna Murali.mp3'






import AOS from 'aos';

import 'aos/dist/aos.css';




function Resources() {
  return (
<div>
  <div className='res_main'>
  <div className='Res_Heading'>
  <p>
    Resources
  </p>
 </div>
  </div>


    
    <div className='resources'>




    <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Nithya Shlokas<span> <a href={pdf3}> PDF Document </a></span></h4>
         
     

                  </div>
                  
        </div>
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Balagokulam Prayer <span> <a href={pdf4}> PDF Document  </a></span></h4>
                  
                  <audio controls>
                      <source src={Prayer} type="audio/ogg"/>
                      <source src={Prayer} type="audio/mpeg"/>
                          Your browser does not support the audio element.
                  </audio>
                
                  </div>
                  
        </div>
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h5 class="r_card-title">Gokulam Syllabus <span> <a href={pdf5}> PDF Document  </a></span></h5>
                 
                
                  </div>
                  
        </div>

        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h5 class="r_card-title">Ramayana Shlokas <span> <a href={pdf6}> PDF Document </a></span></h5>
                 
                
                  </div>
                  
        </div>




<div className='r_Activities-card1'>
 
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Amrutha Vachanam<span> <a href={pdf1}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>

        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Bhajans<span> <a href={pdf2}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>


        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Subhashitham<span> <a href={pdf7}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Bhagavath Gita<span> <a href={pdf9}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Daily Slokas<span> <a href={pdf11}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Bharath Ki Nari Shakthi<span> <a href={pdf10}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Balagokulam Dairy<span> <a href={pdf12}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Games Catalogue<span> <a href={pdf13}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Geetha Dhyanam<span> <a href={pdf14}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Hindu Rituals<span> <a href={pdf15}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Sanadhana Dharma Padanam<span> <a href={pdf17}> PDF Document </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Teacher's HandBook<span> <a href={pdf18}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      
        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Stories<span> <a href={pdf19}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>

        <div className='r_Activities-card1'>
              
              <div class="r_card-body">
                  <h4 class="r_card-title">Yoga Day Celebrations<span> <a href={pdf20}> PDF Document  </a></span></h4>
                 
                
                  </div>
                  
        </div>
      

    </div>

    </div>
  )
}

export default Resources