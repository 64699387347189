import React from 'react';
import './unit.css';
import { Table } from 'react-bootstrap';

function unit() {
  return (
    <div>


        <div className='unit_div'>
          
        <div className='Gokulam-list'>
         
          <h1 className='unit-heading'> Our Units </h1>
          <Table responsive="sm">
            <tr>
              <td>
                SI.No
              </td>
              <td>Units</td>
              <td>Place</td>
              <td>Contact Number</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Manikanda Gokulam </td>
              <td>Kamala Nagar-ECIL</td>
              <td>Sri G Praveen , Mob: 9969198121</td>
            </tr>

            <tr>
              <td>2</td>
              <td>Yashoda Gokulam </td>
              <td>Anand Bagh</td>
              <td>Sri T Anil Kumar , Mob: 9959343506</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Vrindavan Gokulam </td>
              <td>Sainikpuri</td>
              <td>Sri T N Gangadharan , Mob: 9989894417</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Ambadi Gokulam </td>
              <td>Dammaiguda</td>
              <td>Sri P Girish , Mob: 9397979401</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Gayathri Gokulam </td>
              <td>Gayathri Nagar-ECIL</td>
              <td>Sri G Anil Kumar , Mob: 9440563997</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Geetha Gokulam </td>
              <td>Nagaram</td>
              <td>Smt Gowri J , Mob: 7396341205</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Nandanam Gokulam </td>
              <td>Uppal</td>
              <td>Sri J Sasidharan , Mob: 9440665900</td>
            </tr>
          </Table>
          
          </div>
          </div>
    </div>
  )
}

export default unit
