import React from 'react';
import "./SKJ.css";
import img from '../../images/SKJ/12002242_732965970182208_7788088730163815546_n.jpg'
import img1 from '../../images/SKJ/skj_pataka.jpeg';
import img2 from '../../images/SKJ/GoPooja.jpeg';

import img3 from '../../images/SKJ/krishnavesham.jpg';
import img4 from '../../images/SKJ/skj_bg_3.jpg';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


import AOS from 'aos';

import 'aos/dist/aos.css';
import { FadeIn } from 'react-scroll-motion';






function SKJ() {

  AOS.init();

  return (
  <div className='skj'>

  <div>
     <h1 className='skj_heading'>
          Sreekrishna Jayanthi Celebrations
     </h1>
  </div>
       


   <div className='skj_programs'>

      <div className='skj_part1'>
    
         <div className='skj_contents'>
         

          <div className='skj_intro'  >
          <div className='skj_para11'>
              <p>
              Sree Krishna Janmashtami which marks the birth of Bhagawan Sree Krishna is celebrated 
              by Balagokulam across the twin cities in a grand way. Janmashtami is celebrated with
              lots of enthusiasm across various Balagokulams in Hyderabad. 
             </p>
             <p>
             An organizing committee comprising of a Rakshadhikari, President, Secretary, Treasurer, and 
             Aghosha Pramukh is formed who are responsible for the conduct of programs in connection with
             Sree Krishna Jayanthi
             </p>
             </div>

          </div>



           
         

           
          <div className='skj_img1'>
            <img src={img}></img>
        </div>
          
 

            {/*skj contents*/}</div>

         {/*skj part 1 */} </div>

    


        



      <div className='skj_part2'>

      <div className='skj_list' data-aos="fade-right" data-aos-duration="1000">
        
        <ol>
          

          <li style={{listStyleType:'decimal'}}  >
            <h4>Dhwajarohanam: </h4>
            <p>The hosting of the saffron flag which commences the Sree Krishna 
               Jayanthi celebrations.
            </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
            <h4>Gow Matha Pooja:  </h4>
            <p>Devotion to Gomatha, symbolizing our wholehearted affection to the entire 
              living world as demonstrated by Sree Krishna himself.
            </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
            <h4>Rakshabhandan:  </h4>
            <p>A bond of protection-tying the promise of togetherness and spreading 
               universal brotherhood.
            </p>
          </li>

          <li style={{listStyleType:'decimal'}}>
            <h4>Sree Krishna Jayanthi MahaShobhayatra: </h4>
            <p>On the day of Janmashtami, there’s a procession with kids dressed as Krishna, 
              Radha, Gopikas, and other Pauranik characters. This is a visual treat for the 
              general public and serves as a platform to project the mission and vision of Balagokulam.
               The Mahashobhayatra is followed by various cultural events, Bhagavatha parayanam and Dahi
                Handi, and is concluded with Dhwajaavarohanam.
            </p>
          </li>

        </ol>

        {/*skj list*/}</div>

        <div className='skj_img'>
          

   
  <Carousel className='skj_car' autoPlay interval="2500" transitionTime="1500" infiniteLoop 
   showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} > 

 
                            


                <div className='car_img'>  
                    <img src={img1} className='car_img1' />   

                </div>  
                <div className='car_img'>  
                    <img src={img2} className='car_img1'/>   

                </div>  
                <div className='car_img'>  
                    <img src={img3} className='car_img3'/>   

                </div>  

              
                

              
             
          
  </Carousel>

   
  </div>


        {/*skj part 2*/} </div>
  
        

      {/*skj programs*/} </div>

   {/*main div*/} </div> 
    

  )
  }

export default SKJ
