import React from 'react';
import "./Spl_Events.css";

import img1 from '../../images/Gurupooja/gurupooja-guruvandanam.webp';
import img3 from '../../images/SPL/Gokula Nidhi/WhatsApp Image 2023-11-23 at 13.40.53.jpeg';
import img4 from '../../images/SPL/Vishu/WhatsApp Image 2023-11-23 at 13.51.50.jpeg';
import img5 from '../../images/SPL/RamamBhaje/WhatsApp Image 2023-11-23 at 13.20.58.jpeg';
import img6 from '../../images/SPL/Onam.jpeg';
import img7 from '../../images/SPL/thiruvathira2.jpg';
import img8 from '../../images/SPL/SPORTS.jpg';
import img9 from '../../images/SPL/VGMA.bmp';
import img10 from '../../images/SPL/46053338_2212464049033758_7124240924514713600_n.jpg';
import img11 from '../../images/SPL/Ganesh.jpg';
import img12 from '../../images/SPL/vihara_yathra.jpg';
import img13 from '../../images/SPL/Shibiram.jpg';
import img14 from '../../images/SPL/Geeta_Jayanthi.jpg';
import img15 from '../../images/SPL/Amrithabharathi Logo (1).jpg';
import img16 from '../../images/SPL/Gokula_dinam.jpg';
import img17 from '../../images/SKJ/DSC_3360.jpg';
import img18 from '../../images/SPL/Sambarkkam.jpeg';



import AOS from 'aos';

import 'aos/dist/aos.css';




function Spl_Events() {


     AOS.init();


  return (
    <div  className='spl_div'>
      <h1 className='spl_heading'>Balagokulam Special Programs</h1>

      <div className='spl_cards'>
     
     <div className='spl_Activities-card1' >
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title" > Guru Pooja </h4>

             <p className="spl_card-text">In the month of Ashada maasam, every Gokulam conducts Gurupooja utsavam
              very religiously. Gokulam karyakarathas identify and invite an eminent person as the chief guest
               for the event. Various activities related to the guru pooja program are organized as part of 
               the event and it will conclude with a Guru Pooja ritual.</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img1} >
                  </img>
             </div>


   </div>

    </div>



    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'>
       
         

             

             <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Gita Jayanthi</h4>

            
             <p className="spl_card-text">
                  In the month of Vruschikam, Gokulam conducts Gita Jayanthi. The event includes Gita Chanting
                   and various discourses by eminent personalities about the Gita and its significance and
                    conduct. Also to reach out to the general public, Gokulam children/Karyakarthas conduct 
                    Sambarkkam and distribute the Bhagavad Gita.</p>
             
             </div>
             <div className='spl_card-body2'>
                 <img src={img14}></img> 
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Gokula Nidhi </h4>

             <p className="spl_card-text">In the month of Makaram, gokulam conducts Gokula Nidhi in which Children,
              parents, Karyakarthas, and Sympathizers do Gokula Nidhi samarppanam in front of lord Krishna.
               Boudhik by Central Committee member regarding the Importance of Gokula Nidhi and Samarppanam 
               also will be there as part of Gokula Nidhi.</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img3} >
                  </img>
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">

   
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Vishu Kani Darsanam</h4>

             <p className="spl_card-text">Vishu is a Hindu festival celebrated in the Indian state of Kerala. The festival marks the first day of Medam, the ninth month in the solar calendar followed in Kerala. Usually, it falls in the middle of April in the Gregorian calendar on 14th or 15th April every year. 
Each unit of Balagokulam arranges ‘Vishukani’ which is the first thing viewed on that day. It is an arrangement that typically includes a deity of Vishnu, in the form of Krishna decorated with the golden blossoms of the Indian laburnum (Kani Konna), money, gold and silver ornaments, cloth (pattu), mirror, rice, and other harvest products. ‘Vishukaineetam’ is the hansel given to younger members of the family by an elderly person in the family, in which children are given coins. It is an integral part of the celebrations and this ritual is about sharing all spiritual wealth with the younger generation.
</p>
             
             </div>

             <div className='Vspl_card-body2'>
                  <img src={img4} >
                  </img>
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Gokula Dinam </h4>

             <p className="spl_card-text">In the month of Medam, Gokulam conducts Gokula Dinam in which
              children demonstrate various programs such as Gita Parayanam, Storytelling, Bhajans, patriotic
               Geet, dance, etc. which they learn from regular Gokulam class. Kaiyyezhuthu magazine 
               (Handwritten manuscript) was also released on the day of Gokula Dinam.</p>
             
             </div>

             <div className='spl_card-body2'>
                   <img src={img16} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Thiruvathira-Mathrudinam: </h4>

             <p className="spl_card-text">“Thiruvathira" is one of the most important festivals of womenfolk, 
             celebrated in the Malayalam month of 'Dhanu'. The festival duration is about seven days with 
             concluding festivities on the night of 'Ardra Nakshatra'. It is believed that on this day 
             Goddess Parvathi had the first darshan followed by her marriage with Lord Shiva after her 
             long penance. Many rituals are associated with this festival. 'Thiruvathirakali' is an
              exceptional dance form that is part of the festival, in which a group of women attired 
              in traditional dress, dance in a circle around a lamp for seven days. On the concluding day,
               the dance extends for the whole night ('Ardra Jaagaran') singing a number of variety devotional
                songs. Lord Shiva and Goddess Parvathi bless the devotees for their happy family life.</p>
                <p className="spl_card-text">Balagokulam Bhagyanagar hosts Thiruvathira celebrations every year wherein all the families get together 
on this auspicious day. In order to spread the significance of Mathru-Bhavana in society,
 Balagokulam celebrates the Thiruvathira festival as ‘Mathru Dinam’. The celebration includes 
 various programs like visualization of segments from Ramayana, and Mahabaratham by students.
  Thiruvathirakali by the women is the major highlight of this event. 
</p>
             
             </div>

             <div className='Tspl_card-body2'>
                  <img src={img7} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Onam Kudumbasangamam </h4>

             <p className="spl_card-text">Onam is a harvest festival celebrated in Kerala which falls on the 22nd nakshatra 
             i.e; Thiruvonam in the month of Chingam of the Malayalam calendar which in the Gregorian calendar overlaps with
              the months August–September. According to legends, the festival is celebrated to commemorate King Mahabali, 
              whose spirit is said to visit Kerala at the time of Onam. </p>
              <p className="spl_card-text">The families of Balagokulam get together in the evening of a festive day. A floral
               arrangement known as Pookalam followed by Onakalikal- the games like tug of war and, musical chair to name a few,
                forms the vibrant part of this festival.  </p>
             
             </div>

             <div className='Ospl_card-body2'>
                  <img src={img6} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Bala Kalolsavam</h4>

             <p className="spl_card-text">One of the most awaited events by the students of Balagokulam wherein they get a wonderful 
             opportunity to express their talents and creativity in the form of fine arts like music, art and craft, the art of writing
              stories, travelogues. The students are categorized into three different categories [Bala, Kishora, Taruna] considering
               their age as the factor for the conduct of the healthy and fair competition. We include various events of competition 
               such as recitation of Bhagavad Gita and Bhajans, singing patriotic songs, narration of moral stories, writing travelogues,
                elocution, Poster design, street theatre, and quizzes for the masterminds. Each and every student is well appreciated
                 by Balagokulam Bhagyanagar by awarding them with certificates and mementos.</p>
             
             </div>

             <div className='k_spl_card-body2'>
                  <img src={img10} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Ramam Bhaje </h4>

             <p className="spl_card-text">Balagokulam conducts month-long Ramayana Masacharanam in various places in the month of Ashadam
              (Karkkitakam). Gokulam karyakarthas, children, Parents, etc. assemble at various houses and conduct Parayanam in the evening
               hours. In the Gokulam classes, events like narrating stories of Ramayana, Ramayana shlokas, Quiz Competition on Ramayana, 
               Devotional songs, Bhajans, chanting verses from the epic Ramayana, etc. are organized. This event has been introduced with
                the aim of imbibing cultural values to children wherein they get to know about our mythology and also get trained to read
                 the Ramayanam. The program concludes with a grand ‘Sri Rama Pattabhishekam’ karyakarm also at the end of the monthlong
                  celebration.

</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img5} >
                  </img>
             </div>


   </div>

    </div>



    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Ganesh Chathurthi</h4>

             <p className="spl_card-text">Balagokulam Bhagyanagar finds a unique way of celebrating Ganesh Chathurthi.
              We organize an event where in children make their own idols. This enhances the creativity in children and
               also inspires them to go environment-friendly, all the while maintaining the sanctity of the festival.

</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img11} >
                  </img>
             </div>


   </div>

    </div>
    
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Vidya Gopala Manthra Archana </h4>

             <p className="spl_card-text">Vidya Gopala Manthra Archana by Children, parents, etc. as part of Vijaya Dashami celebrations.
              Everyone offers Pushpa Archana and recites 108 times Vidya Gopala Archana to the goddess.

</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img9} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Vihara Yatra (Outing)</h4>

             <p className="spl_card-text">Balagokulam Bhagyanagar arranges every year a one-day vihara yatra to 
             religious and historical places, for all the Gokulam families. 

</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img12} >
                  </img>
             </div>


   </div>

    </div>
    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Sports Meet ( Kreeda Diwas): </h4>

             <p className="spl_card-text">Every year Balagokulam conducts a Sports Meet (Kreeda Diwas) to showcase the energy and enthusiasm of
              children. So that the children can know their abilities and interests in different sports events. This event brings in a 
              competitive spirit and a spirit to excel in their lives. Following were the games played on Kreeda Diwas.</p>
             <p className="spl_card-text"> Running Race, Relay Race, Biscuit Game, Ring Tennis, Sack Race, Tier Race, Tug of war, Throw Ball, Obstacle Race, Chathurangam (X O-game), 
              Kho-Kho, Kabaddi, Long Jump & walking race.


</p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img8} >
                  </img>
             </div>


   </div>

    </div>


    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Common Gokulam</h4>

             <p className="spl_card-text">Balagokulam Bhagyanagar introduced common Gokulam where children and their parents of different 
             Gokulams gathered at one of the Gokulam and common classes were conducted.</p>
             



             
             </div>

             <div className='spl_card-body2'>
                  <img src={img17} >
                  </img>
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title"> Amrutha Bharathi Exams</h4>

             <p className="spl_card-text">Balagokulam Bhagyanagar conducts Amrutha Bharathi Exams for all interested in Sanskrit
language irrespective of their age.</p>
             



             
             </div>

             <div className='spl_card-body2'>
                  <img src={img15} >
                  </img>
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-right" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Sambarkka Yagnam </h4>

             <p className="spl_card-text">Gokulam conducts sambarkka yagnam between Ugadi to Vishu. During Sambarkkam, we give
               greeting cards to all family members. We take this opportunity to enhance the bonding with
               family members of Gokulam.</p>

             
             </div>

             <div className='spl_card-body2'>
                  <img src={img18} >
                  </img>
             </div>


   </div>

    </div>

    <div className='spl_cards'>
     
     <div className='spl_Activities-card1'  data-aos="fade-left" data-aos-duration="1000">
       
         <div className="spl_card-body">
       
              <h4 className="spl_card-title">Shibirams: </h4>

             <p className="spl_card-text">Every year Balagokulam Bhagyanagar conducts shibirams (training workshops) for
                    Rakshadhikari (academic team members), Gokulam Karyakarthas, Balamithra-Bhagini
                    Pramukhas to enhance their organizational as well as academic teaching skills.</p>
             <p className="spl_card-text"> Balagokulam also observes Vivekananda Jayanthi, Chatrapathi Shivaji Jayanthi, Netaji Subhash
                    Chandra Bose Jayanti, Sri Narayana Guru Jayanti, Ayyankali Dinam, Vishwakarma Dinam,
                    Bathukamma etc.
               </p>
             
             </div>

             <div className='spl_card-body2'>
                  <img src={img13} >
                  </img>
             </div>


   </div>

    </div>

   





    </div>
  )
}

export default Spl_Events
