import React, { useState } from 'react';
import './Gallery.css';
import img1 from '../../images/Thiruvathira/_DSC6114.jpg';
import img2 from '../../images/Thiruvathira/_DSC6140.jpg';
import img3 from '../../images/Thiruvathira/_DSC6151.jpg';
import img4 from '../../images/Thiruvathira/_DSC6183.jpg';
import img5 from '../../images/Thiruvathira/_DSC6216.jpg';

import img6 from '../../images/Kreeda/_DSC6281.jpg';
import img7 from '../../images/Kreeda/_DSC6308.jpg';
import img8 from '../../images/Kreeda/_DSC6388.jpg';
import img9 from '../../images/Kreeda/_DSC6392.jpg';
import img10 from '../../images/Kreeda/_DSC6406.jpg';


import img11 from '../../images/SKJ/11108847_732965820182223_5785259162021155744_n.jpg';
import img12 from '../../images/SKJ/DSC_3360.jpg';
import img13 from '../../images/SKJ/skj_dance.jpeg';
import img14 from '../../images/SKJ/skj_pataka.jpeg';
import img15 from '../../images/SKJ/11988347_732966623515476_8355967281365873809_n.jpg';



import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';






function Gallery() {





  const onInit = () => {
    console.log('lightGallery has been initialized');
};
return (
    <div className="gallery">
      <div className='t-img'>

      <h3>
        Thiruvathira Mathrudinam 
      </h3>


      <div className='t_img_inner'>
        <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}

            
        >


         
            <a href={img1}  >

                <img alt={"t1"} src={img1} className='gallery_img' />

            </a>

            
           

            
            <a href={img2}  >

                <img alt={'t2'} src={img2} className='gallery_img'/>

            </a>

            
            <a href={img3}  >

                <img alt={'t3'} src={img3} className='gallery_img'/>

            </a>

            
            <a href={img4}  >

                <img alt={'t4'} src={img4} className='gallery_img'/>

            </a>

            
            <a href={img5}  >

                <img alt={'t5'} src={img5} className='gallery_img'/>

            </a>

            
           
            
        </LightGallery>
        </div>
        </div>





        <div className='k-img'>

      <h3 className='kh'>
        Kreeda Diwas
      </h3>


      <div className='t_img_inner'>
        <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}

            
        >


         
            <a href={img6}  >

                <img alt={"t6"} src={img6} className='gallery_img'/>

            </a>

            
           

            
            <a href={img7}  >

                <img alt={'t7'} src={img7} className='gallery_img'/>

            </a>

            
            <a href={img8}  >

                <img alt={'t8'} src={img8} className='gallery_img'/>

            </a>

            
            <a href={img9}  >

                <img alt={'t9'} src={img9} className='gallery_img'/>

            </a>

            
            <a href={img10}  >

                <img alt={'t10'} src={img10} className='gallery_img'/>

            </a>

            
           
            
        </LightGallery>
        </div>
        </div>


        <div className='skj-img'>

<h3 className='skj-h'>
  Sreekrishna Jayanthi 
</h3>


<div className='t_img_inner'>
  <LightGallery
      onInit={onInit}
      speed={500}
      plugins={[lgThumbnail, lgZoom]}

      
  >


   
      <a href={img11}  >

          <img alt={"t11"} src={img11} className='gallery_img'/>

      </a>

      
     

      
      <a href={img12}  >

          <img alt={'t12'} src={img12} className='gallery_img'/>

      </a>

      
      <a href={img13}  >

          <img alt={'t13'} src={img13} className='gallery_img'/>

      </a>

      
      <a href={img14}  >

          <img alt={'t14'} src={img14} className='gallery_img'/>

      </a>

      
      <a href={img15}  >

          <img alt={'t15'} src={img15} className='gallery_img' />

      </a>

      
     
      
  </LightGallery>
  </div>
  </div>





    </div>
);




  {/*return (
    <div>


      <div className='gallery'>
    
    <div className='g_Thiru'>
      
   <h3>
        Thiruvathira Mathrudinam 
      </h3>
      <img src={img1} className='g_img'></img>
      <img src={img1} className='g_img'></img>
      <img src={img1} className='g_img'></img>
      <img src={img1} className='g_img'></img>
      <img src={img1} className='g_img'></img>
    </div>
    <div className='g_Kreeda'>
      <h3>
       Kreeda 
      </h3>
      <img src={img1} className='g_img'></img>
    </div>
    <div className='g_skj'>
      <h3>
        Sreekrishna Jayanthi 
      </h3>
    </div>

    <div className='g_BK'>
      <h3>
        Bala Kalolsavam 
      </h3>
    </div>
    <div className='g_RB'>
      <h3>
        Ramam Bhaje 
      </h3>
    </div>
    </div>
    </div>
  )*/}
}

export default Gallery