import React from 'react';
import "./Navbar.css";
import { useState } from 'react';

import icon from '../../images/Logo.png';



function Navbar() {
  const [active,setActive]=useState('menu_items');

  const navToggle=()=>{
    active ==="menu_items"
    ? setActive("menu_items nav__active")
    :setActive("menu_items");
  };



  return (
    <nav className='NavBar'>
    

    <div className='Nav_begin'>

   <img src={icon} className='icon'></img>
      
      <div className='Bg_Heading'>
      <h4 >Balagokulam Bhagyanagar</h4>
       <p>MIG-A-13, Dr. A S Rao Nagar, ECIL, Hyderabad-62</p>
      </div>
      
    </div>
     <div className='Nav_list'>

      <ul className={active}>
         <li className='nav_item'><a href='/' className='nav_link'>Home</a></li>
         <li className='nav_item'><a href='/aboutUs' className='nav_link'>About </a></li>
         <li className='nav_item'><a href='/gallery' className='nav_link'>Gallery</a></li>
         <li className='nav_item'><a href='/contactUs' className='nav_link'>Contact us</a></li>
         <li className='nav_item'><a href='/resources' className='nav_link'>Resources</a></li>
         
      </ul>

         <div onClick={navToggle} className='nav__toggler'>

            <div className='line1'></div>
            <div className='line2'></div>
            <div className='line3'></div>

         </div>
    </div>

    </nav>
  );
}

export default Navbar