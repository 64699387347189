import React from 'react'

function AmruthaVachanam() {
  return (
    <div>
      <p>
      "Each nation, like each individual, has one theme in life, which is its centre, the principal note round which
every note comes to form harmony. If one nation attempts to throw off its vitality, the direction which has
become its own through the transmission of centuries, the nation dies. If one nation's political power is its
vitality, as in England, artistic life is another and so on. In India religious life forms the centre, the keynote of
the whole music of life."
-Swami Vivekananda
"To succeed, you must have tremendous perseverance, tremendous will. “I will drink the ocean”, says the
persevering soul; at my will, the mountains will crumble up. Have that sort of energy, that sort of will, and you
will reach the goal."
-Swami Vivekananda
"Look upon every man, woman, and everyone as God. You cannot help anyone, you can only serve: serve
the children of the Lord, serve the Lord Himself, if you have the privilege."
-Swami Vivekananda
"Stand up, be bold, be strong. Take the whole responsibility on your own shoulders, and know that you are
the creator of your own destiny. All the strength and succour you want is within yourselves. Therefore, make
your own future. Do not go about throwing mud at others; for all the faults you suffer from, you are the sole
and only cause."
-Swami Vivekananda
"Take up one idea. Make that one idea your life - think of it, dream of it, and live on that idea. Let the brain,
muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is
the way to success; that is the way great spiritual giants are produced."
-Swami Vivekananda
"Be strong! Don’t talk of ghosts and devils. We are the living devils. The sign of life is strength and growth.
The sign of death is weakness. Whatever is weak, avoid! It is death. If it is strength, go down into hell and get
hold of it! There is salvation only for the brave."
-Swami Vivekananda
"The ‘I’ in me being the same as the ‘I’ in other beings, makes me react to joys and sorrows of my fellow
living beings just as I react to my own. This genuine feeling of Identity, born out of community of the inner
entity, is the real driving force behind our natural urge for human unity and brotherhood."
– Poojaniya Shri Guruji Golwalkar
–
"WE, the children of this great and ancient nation, naturally desire that our nation should scale ever new
heights of prosperity, glory and honour in this world. This is a very legitimate desire, no one can deny it.
However, life is not all smooth-sailing in this world of hard reality. Even in the fulfilment of just and legitimate
desire one is confronted with impediments and challenges from outside forces. If we have to bring to life the
dream of our national glory, we have to assess and analyse the nature of this challenge and prepare
ourselves to meet it effectively."
-Poojaniya Shri Guruji Golwalka
      </p>
    </div>
  )
}

export default AmruthaVachanam
