import React from 'react';
import './Recent.css';
/*import img1 from '../../images/Thiruvathira/_DSC6216.jpg'*/
import img1 from '../../images/SPL/Vishu/WhatsApp Image 2023-11-23 at 13.51.50.jpeg';
import img2 from '../../images/Manuscript.jpeg';

function Recent() {
  return (
    <div className='recent'>
        <div className='recent_div'>
        

        <h1 >Vishu Kani Darshan:</h1>
        <div className='recent_div2'>
         
        

       <div className='recent_div3'> 
          <p>
        Vishu is a Hindu festival celebrated in the Indian state of Kerala.
           The festival marks the first day of Medam, the ninth month in the solar
           calendar followed in Kerala. Usually, it falls in the middle of April in the
            Gregorian calendar on 14th or 15th April every year. </p>
           <p> This year also we celebrated 'Vishu' on April 15 2024 (Monday) in our gokulam units
           Each unit of Balagokulam arranged ‘Vishukani’ which is the first thing viewed on that day. 
           It is an arrangement that typically includes a deity of Vishnu, in the form of Krishna 
           decorated with the golden blossoms of the Indian laburnum (Kani Konna), money, gold and silver
            ornaments, cloth (pattu), mirror, rice, and other harvest products. ‘Vishukaineetam’ is the 
            hansel given to younger members of the family by an elderly person in the family, in which 
            children are given coins. 
          </p>
           </div>
 

        <div className='recent_div4'>

          <img src={img1} className='recent_div4_img' >

          </img>

        </div>
       
         


        </div>



        </div>

        <div className='recent_div'>
        

        <h1 >Kaiyezhuth Masika(Hand-written Magazine) Prakashan</h1>
        <div className='recent_div2'>
         
        

       <div className='recent_div3'> 
       <p></p>
          <p>
          Manikanta Balagokulam released a handwritten manuscript of this year on 15 April 2024 at Sabari Sadan ,ECIL . 
          Shri V Madhavan Nampoothiri, Dr. K. P. Reghunatha Menon,and Shri P. Satish Kumar addressed 
          the event. Smt. Anisha Anil, Sreeja Ranjith,Sreevidya S and Shri G Praveen are in charge of
           creating the handwritten manuscript. </p>
           
          
           </div>
 

        <div className='recent_div4'>

          <img src={img2} className='recent_div4_img1' >

          </img>

        </div>
       
         


        </div>



        </div>


    </div>
  )
}

export default Recent